.title-with-strip{
    margin: 36px auto;
    padding-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
  }
  
  .title-with-strip::before, .title-with-strip::after{
    content:"";
    display: inline-block;
    width:70px;
    height: 5px;
    background:var(--waring-color);
    margin-right: 30px;
  }

  .title-with-strip::after{
    margin-right: 0;
    margin-left: 30px;
  }
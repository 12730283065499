html,body,#root,.App{
  width: 100%;
  height:100%;
}

#login{
  display: flex;
  grid-template-columns: auto 32.71%;
  min-height: 100vh;
}
.login_left{
  background: url('../images/login-bg.jpg') no-repeat;
  background-size: 100% auto;
  flex: 1;
}
.login_right{
  width: 35%;
  padding:21px 37px 125px 37px;
  margin:0;
}

.logo_image{
  max-width:24rem;
  height:8.75rem;
  background: url('../images/logo.png') no-repeat center;
  background-size: 80%;
  margin-left: -15px;
}
.login_right .row{
  margin:0;
}

.login .form-item>div{
  position: relative;
}
.login .form-item span.icons{
  position: absolute;
  left: 13px;
  width: 15px;
  height: 46px;
  display: block;
}

.userIcon{
  background: url('../images/user_icoin.png') no-repeat center;
  background-size: 100%;
}
.passwrodIcon{
  background: url('../images/password_icoin.png') no-repeat center;
  background-size: 100%;
}
.login .form-control{
  padding-left:40px;
  background: #F6F6F6;
  border-radius: 0;
  line-height: 2;
  height: 46px;
}
.login .btn-danger{
  width: 100%;
  border-radius: 0px;
  background: rgb(195, 54, 37);
  margin-top: 50px;
  line-height: 2;
}

.loading{
    background: url('../images/loading.gif');
    background-repeat: no-repeat;
    background-size: 40px;
}

span.loading{
    background: url('../images/loading.gif');
    display: inline-block;
    width: 20px;
    height: 1em;
    background-size: 100%;
}

div.loading{
    text-align: center;
    position: absolute;
    height: 50px;
    width: 100%;
}

.error-main{
  width: 50%;
  position: absolute;
  top: 30vh;
  left: 25%;
}

.blockview-grid{
  border: 1px solid #e6e6e6 !important
}

.browse-list{
  min-width: 800px;
}

.browse-list ul{
  margin: 0px;
  padding: 0px;
}

.browse-list .col-4{
  width: 15%;
}

.browse-list .col-8{
  width: 85%;
}

.browse-list .col-8 > a{
  display: none;
}

.browse-list .foldable.space{
  display: none;
}

.browse-list ul li{
  padding: 3px 0px;
  display: block;
  border-bottom: 1px dotted #cccccc;
}

.browse > .selected{
  position: absolute;
  background: #fcfcfc;
  padding: 5px;
  right: 5px;
  border-radius: 5px;
  top: 10px;
}

.browse .selected img{
  max-height: 80px !important;
  max-width: 80px;
}

.contenttype-image > .field-name{
  font-size: 0.85rem;
  color: #333333;
}

.contenttype-image > .field-image img{
  height: 80px;
  object-fit: contain;
}

.browse ul li span.selected{
  color:red;
}

.column-sortable.desc::after{
  content: '▼';
  font-size: 12px;
  margin-left: 5px;
}


.column-sortable.asc::after{
  content: '▲';
  font-size: 12px;
  margin-left: 5px;
}

@media only screen and (max-width: 600px) {
  .login_right{
    width: 100%;
  }
}
@import "~digimaker-ui/digimaker-ui.css";

@import "./admin-common.css";
@import "./bridge.css";

.App-header {
  background-image: url('../images/header-bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 200px;
  position: relative;
}


.header-menu{
  background-color: rgba(255, 255, 255, 0.85);
  padding: 10px 40px;
  position: absolute;
  bottom: 0px;
  left: 30%;
}

.header-menu a{
  font-size: 1.2rem;
  padding: 10px 20px;
}

a{
  text-decoration: none;
}

:root{
  --bs-link-color: rgb(0, 52, 97);
  --bs-link-color-rgb: 0, 52, 97;

  --bs-link-hover-color: rgb(60, 119, 201);
  --bs-link-hover-color-rgb: 60, 119, 201;

  --dmee-bottom-height: 30px;

  --preview-container-min-width: 960px;
  --preview-container-gap: 100px;
  
}

.btn-link{
   text-decoration: none;
   color: var(--bs-link-color);
}

/* a:hover{
  color:rgb(60, 119, 201)
} */

.App-main{
  width: clamp(1200px, 90vw,  100vw);
  margin: auto;
  min-height: 60vh;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.main{
  width: 75%;
}

.side{
  width: 25%;
  background: #f7f8fc;
  padding: 15px;
}

.footer{
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  background-color: #f0f0f0;
  font-size: 90%;
}

a.active{
   color: rgb(255, 47, 0);
   font-weight: bold;
}

.submenu{
  list-style: none;
  margin-bottom: 0px;
  padding: 5px 0px;
}

.submenu li{
  display: inline-block;
  padding: 3px 0px;
}

.submenu .menu-item{
  padding-left: 8px;
  padding-right: 8px;
}

.submenu .sort-item{
  margin-left: 10px;
}

.submenu i{
  margin-right: -1px;
  color:rgba(0,0,0,0);  
}

:focus{
  outline: none;
}

.submenu .sort-item:not(:first-child), .submenu .menu-item:not(:first-child){
  border-left: 1px solid #cccccc;
}

.submenu i:hover{
  cursor:move;
  color:red;
}

.submenu > li:not(:first-child){
  /* border-left: 1px solid #cccccc; */
}

.right{
  float: right;
}

.main{
  padding:0px 15px 50px 15px;
  overflow: auto;
}


.content-actions {
  float: right;
}

.content-actions > div{
  display: inline-block;
  margin-left: 10px;
}

img{
  max-width: 100%;
}

.listmode-list tr:nth-child(2n){
  background-color: #f0f6ff;
}


.text-right{
  text-align: right;
}

.dm-pagination a{
  padding: 0px 5px;
}

.author-name{
  max-width: 80px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.field-coverimage.field-viewmode-inline{
  width: 100px;
}

.submenu-container{
  background-color: #f7f7f7;
  padding: 5px 0px;
  margin-bottom: 10px;
}

.action-item{
  margin-left: 4px;
  display: inline-block;
}

.side h3{
  font-size: 20px;
  margin-bottom: 10px;
}


.header-right{  
  max-width: 1100px;
  margin: auto;
  /* text-align: right; */
  display: flex;
  flex-direction: row-reverse;
  color: white;
}

.header-right > *{
  padding: 5px 10px;
  background-color: rgba(0,0,0,0.5);
}

.header-right > *:not(:last-child){
  border-left: 1px solid rgb(104, 104, 104);
}

.App-header h1{
  color: white;
  margin:auto;
  letter-spacing:1px;
  max-width:1200px;
  text-shadow: 0px 2px 1px #646464;
}

.side a{
  padding: 8px 10px;
  display: inline-block;
  background-color: white;
  width: 100%;
  border: 1px solid #dddddd;
  margin-top: 5px;
  font-size: 1.1em;
}

.form-actions{
  text-align: right;
}

.action-body > div{
  display: inline-block;
  margin-right: 5px;
}

.field-mode-edit{
  margin-top: 10px;
}

.field-label{
  width: 150px !important;
}

.listmode-list h3{
    font-size: 1.2rem;
    color: #333333;
}

.table td .fieldtype-image img{
  max-height: 30px;
}

.blockview-cell .content-baseattr-published{
  font-size: 0.8rem;
  font-style: italic;
  color: #999999;
}

.list-row-tool{
  padding: 8px 0px !important;
}

.gallery-image img{
  cursor: pointer;
}

.gallery-image img:hover{
  opacity: 0.95;
}

.field-relationlist-image .field-image > img{
  max-height: 100px;
  max-width: 100px;
}

.content-fields-view > .field-container{
  display: none;;
}

.content-fields.content-fields-view .field-id-title{
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.preview-content, .preview-dmeditor{
  border-top: 1px solid #e8e8e8;
  margin: 10px auto;
  overflow-x: auto; 
}

.preview-dmeditor.contenttype-site{
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}

.preview-dmeditor.contenttype-site .dmeditor-container{
  min-width: 960px;
}

.preview-content{
  margin-top: 30px;
  padding: 20px;
  background-color: #fdfdfd;
}

.field-mode-view.fieldtype-relationlist .list-item{
  display: inline-block
}

.field-container{
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-top: 10px;
}

.listmode-list .list-row-tool{
  min-width: 80px;
}

#morenav-dropdown::after{
  display: none;
}

.dme-block-container .article_list a{
  pointer-events: none;
}

.w-container{
  min-width: 0px !important;
  width: auto !important;
}

h2.title{
  margin: 20px 0px;
  font-size: 1.6em;
}

.create-contenttype-page{
  padding: 20px 10px;
}
.create-contenttype-page .field-id-meta_keywords, .create-contenttype-page .field-id-meta_description{
  display: none;
}

.flex.justify-between{
  display: flex;
}

.dme-utility-imageSetting{
  width: 40%;
}


legend{
  width: auto;
  float: none;
  font-size: 15px;
}

.content-list-tools{
  display: flex;
  margin-bottom: 10px;
}

.website-layout{
  display: flex;
}

.icon-info::before{
   content: 'i';
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  background: rgb(0, 111, 0);
  color: white;
  border-radius: 10px;
  text-align: center;
  font-style: normal;
  font-size: 0.75rem;
  margin: 0px 5px;
  line-height: 1.1rem;
  cursor: pointer;  
}

.icon-info:hover{
  opacity: 0.8;
}

.listmode-list tr:hover{
  background: #f0f0f0;
}

@media only screen and (max-width: 600px) {
   
  :root{
    --preview-container-min-width: 100vw;
    --preview-container-gap: 0px;
  }

  .preview-dmeditor.contenttype-site .dmeditor-container{
    min-width: 0px;
  }

  .right{
    float:none;
  }

  .App-main{
    width: 100%;
  }
  .website-layout{
    flex-direction: column-reverse;
  }

  .App-header{
    height: auto;
    background-size: auto 100%;
  }

  .main{
    width: 100%;
  }

  .side{
    width: 100%;
  }

  .header-menu{
    padding: 10px 0px;
    position: relative;
    left:0px;
  }
}